import Image from 'next/image';
import { Box, Button, styled, Typography, Stack } from '@mui/material';
import { MotionContainer } from '@/app/components/animate';

import InViewAnimationComponent from '@/app/components/in-view-animation-container';
import Iconify from '@/app/components/iconify';
import { useBoolean } from '@/app/components/hooks/use-boolean';
import VideoModal from '@/app/components/video-modal';

const NextImage = styled(Image)`
  position: relative !important;
  object-fit: contain;
`;

export default function HeroBanner() {
  const dialog = useBoolean();

  const handleScroll = () => {
    const section = document.querySelector('#download-apps');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <MotionContainer>
      <InViewAnimationComponent>
        <Box
          sx={{
            mx: 'auto',
            width: {
              md: '100%',
              lg: '1200px',
            },
          }}
        >
          <Stack
            sx={{
              height: '100vh',
              minHeight: 600,
              flexDirection: {
                md: 'row',
                xs: 'column',
              },
              flex: 1,

              paddingLeft: {
                md: '32px',
                xs: '0',
              },
              paddingRight: {
                md: '32px',
                xs: '0',
              },
            }}
          >
            <Stack
              sx={{
                flex: {
                  xs: 1,
                  md: 1,
                },
                justifyContent: 'center',
                pt: {
                  xs: '100px',
                  md: 0,
                },
                alignItems: {
                  xs: 'center',
                  md: 'start',
                },
                textAlign: {
                  xs: 'center',
                  md: 'start',
                },
              }}
              gap={4}
              zIndex={10}
            >
              <Typography variant="h1">Smarters Max IPTV</Typography>
              <Typography variant="h5" sx={{ maxWidth: 400 }}>
                Stream effortlessly with Smarters Max IPTV, the leading IPTV player offering high
                quality and multiple platforms. Enjoy high-quality content today!
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                }}
              >
                <Button
                  startIcon={<Iconify icon="ant-design:download-outlined" />}
                  variant="outlined"
                  size="medium"
                  color="primary"
                  onClick={handleScroll}
                >
                  Download Today!
                </Button>
                {/* <Button
                  variant="outlined"
                  startIcon={<Iconify icon="octicon:play-24" />}
                  size="medium"
                  color="primary"
                  onClick={dialog.onTrue}
                >
                  Play video
                </Button> */}
              </Box>
            </Stack>
            <Stack
              sx={{
                flex: {
                  xs: 1,
                  md: 1,
                },
              }}
              zIndex={10}
              justifyContent="center"
              alignItems="center"
            >
              <Box
                sx={{
                  width: {
                    xs: '50%',
                    md: '100%',
                  },
                }}
              >
                <NextImage alt="home-tv" src="/assets/illustrations/tv-image.png" fill />
              </Box>
            </Stack>
          </Stack>
        </Box>
      </InViewAnimationComponent>
      <VideoModal dialog={dialog} />
    </MotionContainer>
  );
}
